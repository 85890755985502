
import { defineComponent, onMounted, ref } from 'vue';

import { copy } from '@/tools';
import { ElButton, ElMessage } from 'element-plus';
import { getFeedlist } from '../api/feed';
import FormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';

export default defineComponent({
  name: 'FeedSoldOut',
  setup() {
    const link = ref('');
    const loading = ref(false);
    const initLink = async () => {
      const { status, data } = await getFeedlist({ page: 1, page_size: 0, keyword: '' }, loading);
      if (!status) return;
      link.value = data?.outOfStockFeed || '';
    };
    const copyLink = () => {
      copy(link.value) &&
        ElMessage({
          type: 'success',
          message: '\u590D\u5236\u6210\u529F',
        });
    };
    onMounted(() => {
      initLink();
    });
    return { link, copyLink, loading };
  },
  components: { FormSkeleton, ElButton },
});
