import request from '@/tools/request';
import {
  GetCategorylist,
  CategorylistRes,
  GoodsTagsRes,
  GetGoodsTags,
  FeedlistRes,
  GetFeedlist,
  Save,
  SaveRes,
  DelRes,
  Del,
  FormDataRes,
  GetFormData,
} from '../types/feed';

// 主分类列表
export const getCategorylist: GetCategorylist = loading => {
  return request({
    url: '/criteo-feed-categorylist.html',
    loading,
  }) as Promise<CategorylistRes>;
};

// 商品标签列表
export const getGoodsTags: GetGoodsTags = params => {
  return request({
    url: '/criteo-feed-taglist.html',
    params,
  }) as Promise<GoodsTagsRes>;
};

// 商品推荐列表
export const getRecommend: GetGoodsTags = params => {
  return request({
    url: '/criteo-feed-recommendlist.html',
    params,
  }) as Promise<GoodsTagsRes>;
};

// Feed管理列表
export const getFeedlist: GetFeedlist = (params, loading) => {
  return request({
    url: '/criteo-feed-list.html',
    loading,
  }) as Promise<FeedlistRes>;
};

// 新增/编辑
export const save: Save = (data, loading) => {
  return request({
    url: '/criteo-feed-save.html',
    loading,
    method: 'POST',
    data,
  }) as Promise<SaveRes>;
};

// 删除
export const del: Del = params => {
  return request({
    url: '/criteo-feed-del.html',
    params,
  }) as Promise<DelRes>;
};

// 获取表单列表
export const getFormData: GetFormData = (params, loading) => {
  return request({
    url: '/criteo-feed-detail.html',
    loading,
    params,
  }) as Promise<FormDataRes>;
};
