
import { defineComponent, onMounted, ref } from 'vue';
import MTable from '@/components/table/Table.vue';
import { getRoute } from '@/tools/config';
import { FeedDataList } from '../types/feed';
import { del, getFeedlist } from '../api/feed';
import { ElButton, ElMessage, ElMessageBox, ElTableColumn } from 'element-plus';
import { TypedMessageParams } from 'element-plus/lib/el-message/src/types';
import { RouterLink } from 'vue-router';
import { copy } from '@/tools';

export default defineComponent({
  name: 'FeedList',
  setup() {
    const tableList = ref<FeedDataList[]>([]);
    const loading = ref(false);

    const pagination = ref({
      total: 0,
      pageSize: 10,
    });
    const initTable = async (page?: number) => {
      const res = await getFeedlist(
        {
          page: page || 1,
          page_size: 10,
        },
        loading,
      );

      if (!res.status) return;

      const { data } = res;

      tableList.value = res.data?.list || [];

      pagination.value.total = data?.pages?.total || 0;
      pagination.value.pageSize = data?.pages?.page_size || 10;
    };

    // 删除
    const triggerDel = async (element: FeedDataList, index: number) => {
      ElMessageBox.confirm('此操作将永久删除该配置，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          element.delLoading = true;

          const { status } = await del({ id: element.id });
          element.delLoading = false;

          if (status) {
            tableList.value.splice(index, 1);
            ElMessage.success({
              message: '删除成功',
            } as TypedMessageParams<'success'>);
          }
        })
        .catch(() => {});
    };

    // 复制
    const copyLink = (link: string) => {
      if (copy(link)) {
        ElMessage({
          type: 'success',
          message: '复制成功',
        });
      }
    };

    const tableEnter = (row: FeedDataList) => {
      tableList.value.forEach(item => {
        if (item.id === row.id) {
          item.enter = true;
        }
      });
    };
    const tableLeave = (row: FeedDataList) => {
      console.log('j');

      tableList.value.forEach(item => {
        if (item.id === row.id) {
          item.enter = false;
        }
      });
    };

    onMounted(() => {
      initTable();
    });

    return {
      tableList,
      loading,
      getRoute,
      pagination,
      triggerDel,
      copyLink,
      tableEnter,
      tableLeave,
    };
  },
  components: { MTable, RouterLink, ElButton, ElTableColumn },
});
