
import { defineComponent, ref } from 'vue';
import PageTab from '@/components/pageTab/PageTab.vue';
import FeedList from '../components/FeedList.vue';
import FeedSoldOut from '../components/FeedSoldOut.vue';

export default defineComponent({
  name: 'Feed',
  setup() {
    const pageTitle = ref([
      {
        text: 'Feed管理',
        isActive: true,
      },
      {
        text: '下架商品Feed',
      },
    ]);
    // 换页
    const changeTab = (index: number) => {
      pageTitle.value.forEach(item => {
        item.isActive = false;
      });

      pageTitle.value[index].isActive = true;
    };
    return {
      changeTab,
      pageTitle,
    };
  },
  components: { PageTab, FeedList, FeedSoldOut },
});
