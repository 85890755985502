
import { PropType } from '@vue/runtime-core';

interface PageTitle {
  isActive?: boolean;
  text: string;
}
export default {
  name: 'PageTab',
  props: {
    pageTitle: {
      type: Array as PropType<PageTitle[]>,
      required: true,
    },
  },
  emits: ['update'],
  setup(props, context) {
    function hanldActive(index) {
      context.emit('update', index);
    }
    return {
      hanldActive,
    };
  },
};
